import { connect, useDispatch } from "react-redux";
import * as React from "react";
import { useEffect } from "react";
import PageContainer from "../../shared/components/container/PageContainer";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { useLocation, useParams } from "react-router-dom";
import PaymentStep1 from "./PaymentStep1";
import PaymentStep2 from "./PaymentStep2";
import PaymentStep3 from "./PaymentStep3";
import _ from "lodash";
import { loadPaymentData, updateBilling } from "../../actions/paymentActions";
import StepLoading from "../../shared/components/StepLoading";
import { loadLoanData } from "../../actions/loanActions";


const mapStateToProps = (state) => ({
  isLoadingUser: state.auth.account === null,
  user: state.auth.account,
  isLoadingLoan: state.loan.data === null,
  loan: state.loan.data,
  isLoadingPayment: state.payment.billing === null || state.payment.paymentMethods === null,
  billing: state.payment.billing,
  paymentMethods: state.payment.paymentMethods
});

const Payment = ({
                   isLoadingUser,
                   user,
                   isLoadingLoan,
                   loan,
                   isLoadingPayment,
                   billing,
                   paymentMethods
                 }) => {
  const location = useLocation();
  const { type } = useParams();

  const getStepName = (type) => {
    switch (type) {
      case "full":
        return "Pay balance in full";
      case "past-due":
        return "Pay past-due amounts";
      case "stand-alone":
        return "Make stand-alone payment";
    }
  };

  const steps = [getStepName(type), "Billing Information", "Payment Information"];

  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = React.useState(0);
  const [amount, setAmount] = React.useState(0.0);

  useEffect(() => {
    if (!loan) {
      dispatch(loadLoanData());
    }
    if (!billing) {
      dispatch(loadPaymentData());
    }
  }, [dispatch, loan, billing]);

  if (!loan || !user) {
    return null;
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const onCompleteStep1 = (amount) => {
    setAmount(+amount);
    handleNext();
  };

  const onCompleteStep2 = async (newBilling, save) => {
    const isSameData = _.isEqual(newBilling, billing);
    if (save && !isSameData) {
      dispatch(updateBilling(newBilling));
    }
    handleNext();
  };

  return (
    <PageContainer title='Make Payment'>
      <Stepper activeStep={activeStep}>
        {steps.map((label) => {
          return (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {(isLoadingLoan || isLoadingUser || isLoadingPayment) ?
        (
          <StepLoading />
        ) :
        (
          <>
            {activeStep === 0 && <PaymentStep1 loan={loan}
                                               type={type}
                                               onComplete={onCompleteStep1} />}
            {activeStep === 1 && <PaymentStep2 billing={billing}
                                               amountToPay={amount}
                                               onComplete={onCompleteStep2}
                                               onBack={handleBack} />}
            {activeStep === 2 && <PaymentStep3 paymentType={type}
                                               billing={billing}
                                               paymentMethods={paymentMethods}
                                               amountToPay={amount}
                                               onBack={handleBack} />}
          </>)
      }
    </PageContainer>
  );
};

export default connect(mapStateToProps)(Payment);
