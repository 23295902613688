import apiService from "../services/apiService";


export const loadActivities = () => async dispatch => {
  try {
    dispatch({
      type: "ACTIVITY_LOAD"
    });
    const activities = await apiService.getActivities();
    dispatch({
      type: "ACTIVITY_SUCCESS",
      payload: activities.data
    });
  } catch (e) {
    console.log("Could not get activities", e);
  }
};
