import { Grid, Skeleton } from "@mui/material";
import React from "react";


const WelcomeLoading = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={8}>
        <Skeleton variant='h1' />
        <Skeleton variant='h1' />
        <Skeleton variant='h1' />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </Grid>
      <Grid item xs={4}>
        <Skeleton variant='rectangular' height={50} />
      </Grid>
    </Grid>
  );
};

export default WelcomeLoading;
