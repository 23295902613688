import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import PageContainer from "../../shared/components/container/PageContainer";
import { Grid, Stack } from "@mui/material";
import WelcomeDetails from "./WelcomeDetails";
import BalanceDetails from "./BalanceDetails";
import PaymentOptions from "./PaymentOptions";
import PaymentDetails from "./PaymentDetails";
import { loadLoanData } from "../../actions/loanActions";
import { loadActivities } from "../../actions/activityActions";
import ActivityDetails from "./ActivityDetails";
import RequestCallbackBlock from "./RequestCallbackBlock";

const mapStateToProps = (state) => ({
  user: state.auth.account,
  loan: state.loan.data,
  activities: state.activity.data
});

const Dashboard = ({ user, loan, activities }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!loan) {
      dispatch(loadLoanData());
    }
    if (!activities) {
      dispatch(loadActivities());
    }
  }, [dispatch, loan, activities]);

  return (
    <PageContainer title='Dashboard'>
      <Grid container>
        <Grid item md={8} sm={8} xs={12} p={1}>
          <Stack direction='column' spacing={2}>
            <WelcomeDetails user={user} loan={loan} />
            <BalanceDetails user={user} loan={loan}/>
            <PaymentDetails user={user} loan={loan} />
            <ActivityDetails activities={activities} />
          </Stack>
        </Grid>
        <Grid item md={4} sm={4} xs={12} p={1}>
          <RequestCallbackBlock user={user} loan={loan} />
          <PaymentOptions user={user} loan={loan} />
        </Grid>
      </Grid>
    </PageContainer>
  );
};

export default connect(mapStateToProps)(Dashboard);
