import React from "react";
import DashboardPayCard from "./shared/DashboardPayCard";
import {
  IconAlarm,
  IconCashBanknote,
  IconFileText,
  IconMessage2
} from "@tabler/icons";
import DashboardCardLoading from "./shared/DashboardCardLoading";
import { Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const PaymentOptions = ({ user, loan }) => {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));

  if (!user || !loan) {
    return <DashboardCardLoading />;
  }

  const hasPaymentOptions = (user.open || !user.good);

  return (
    <Stack direction='column'
           spacing={1}
           style={{
             display: "flex",
             justifyContent: "center",
             alignItems: isSm ? "center" : "start"
           }}>
      <DashboardPayCard icon={<IconFileText size={48} />}
                        title='Apply for New Loan'
                        description='Click to submit your details and proceed with the application process'
                        link='/apply-new-loan' />
      {hasPaymentOptions &&
        <>
          <Typography variant='h5' padding={1}>Debit card payment
            options*</Typography>

          <DashboardPayCard icon={<IconCashBanknote size={48} />}
                            title='Pay balance in full'
                            description='Pay your balance in full today and never have to worry about it again'
                            link='/pay/full' />
          {loan.pastDueAmount > 0.0 &&
            <DashboardPayCard icon={<IconAlarm size={48} />}
                              title='Pay past-due amounts'
                              description='Cannot pay your balance in full? Get caught up by paying at least your past-due amount'
                              link='/pay/past-due' />}
          <DashboardPayCard icon={<IconMessage2 size={48} />}
                            title='Make stand-alone payment'
                            link='/pay/stand-alone'
                            description='Want to make a payment toward my balance of a different amount' />
          <Typography variant='caption' padding={1}>* Our customer portal can
            only
            accept debit card payments at this time. If you want to pay by other
            methods, including ACH, give us a call and we can do that over the
            phone.</Typography>
        </>}
    </Stack>
  );
};

export default PaymentOptions;
