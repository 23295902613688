import React from "react";
import {
  Box,
  Chip,
  Fab,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import { IconCash } from "@tabler/icons";
import DashboardCard from "./shared/DashboardCard";
import { formatDate } from "../../services/date-uitils";
import {
  capitalizeFirstLetter,
  formatPriceNumber
} from "../../services/string-utils";
import DashboardCardLoading from "./shared/DashboardCardLoading";

const PaymentDetails = ({ user, loan }) => {

  if (!user || !loan) {
    return <DashboardCardLoading />;
  }

  if ((!user.open && user.good) || user.pendingReUp === true) {
    return null;
  }

  if (!user.good) {
    return (<>
      <DashboardCard>
        <Stack spacing={2}
               display='flex'
               direction='row'
               justifyContent='space-between'
               alignItems='center'
               sx={{ flexGrow: 1 }}>
          <Typography color='red' variant='h6'>Your current past-due amount
            is:</Typography>
          <Typography color='red'
                      variant='h6'>${formatPriceNumber(loan.feesAndCharges)}</Typography>
        </Stack>
        <Box height={25} />
        <Typography variant='h6'>
          Your Account is in delinquent due to a missed or returned
          payment. <br />We have
          several
          options available for you to catch-up and bring your account
          current. <br />
          You can schedule a payment today to resolve your account by choosing a
          payment option. <br />
          If needed, we will work with you to set up an easy payment
          plan. <br />
          Give us a call or request a call back above to resolve this today.
        </Typography>
      </DashboardCard>
    </>);
  }

  if (!loan.lastPaymentDate && !loan.nextPaymentDate && !loan.scheduledPaymentDate) {
    return null;
  }

  const lastPaymentDateStr = formatDate(loan.lastPaymentDate);
  let lastPaymentStatus = capitalizeFirstLetter(loan.lastPaymentStatus);
  if (loan.lastPaymentStatus === "SENT") {
    lastPaymentStatus = "Pending";
  }
  let lastPaymentBadgeColor;
  switch (loan.lastPaymentStatus) {
    case "PENDING":
      lastPaymentBadgeColor = "warning";
      break;
    case "RETURNED":
      lastPaymentBadgeColor = "error";
      break;
    default:
      lastPaymentBadgeColor = "primary";
      break;
  }
  const nextPaymentDateStr = formatDate(loan.nextPaymentDate);

  const scheduledPaymentDateStr = formatDate(loan.scheduledPaymentDate);

  return (
    <DashboardCard title='Payments'
                   action={
                     <Fab color='secondary' size='medium'
                          sx={{ color: "#ffffff", cursor: "default" }}>
                       <IconCash width={24} />
                     </Fab>
                   }
    >
      <Table size='small'>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant='h6'>
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant='h6'>
                Date
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant='h6'>
                Amount
              </Typography>
            </TableCell>
            <TableCell align='right'>
              <Typography variant='h6'>
                Status
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {lastPaymentDateStr !== "" && <TableRow>
            <TableCell>
              <Typography variant='h6'>
                Previous Payment
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant='h6'>
                {lastPaymentDateStr}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant='h6'>
                ${formatPriceNumber(loan.lastPaymentAmount)}
              </Typography>
            </TableCell>
            <TableCell align='right'>
              <Chip
                sx={{
                  px: "4px",
                  color: "#fff"
                }}
                size='small'
                label={lastPaymentStatus}
                color={lastPaymentBadgeColor}
              ></Chip>
            </TableCell>
          </TableRow>}
          {scheduledPaymentDateStr !== "" && <TableRow>
            <TableCell>
              <Typography variant='h6'>
                Pending Payment
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant='h6'>
                {scheduledPaymentDateStr}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant='h6'>
                ${formatPriceNumber(loan.scheduledPaymentAmount)}
              </Typography>
            </TableCell>
            <TableCell align='right'>
              <Chip
                sx={{
                  px: "4px",
                  color: "#fff"
                }}
                size='small'
                label='Pending'
                color='warning'
              ></Chip>
            </TableCell>
          </TableRow>}
          {nextPaymentDateStr !== "" && <TableRow>
            <TableCell>
              <Typography variant='h6'>
                Next Payment
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant='h6'>
                {nextPaymentDateStr}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant='h6'>
                ${formatPriceNumber(loan.nextPaymentAmount)}
              </Typography>
            </TableCell>
            <TableCell align='right'>
            </TableCell>
          </TableRow>}
        </TableBody>
      </Table>
    </DashboardCard>
  );
};

export default PaymentDetails;
