const initialState = {
  featureFlags: null
};

const analyticsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FEATURE_FLAGS_LOAD":
      return { ...state, featureFlags: null };
    case "FEATURE_FLAGS_SUCCESS":
      return { ...state, featureFlags: action.payload };
    case "LOGOUT":
      return initialState;
    default:
      return state;
  }
};

export default analyticsReducer;
