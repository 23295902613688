import React, { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import useMediaQuery from "@mui/material/useMediaQuery";
import { IconLogout, IconMenu } from "@tabler/icons";
import { logout } from "../../../../actions/authActions";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const MobileMenu = ({}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const isMobile = useMediaQuery(theme => theme.breakpoints.down("sm"));

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setAnchorEl(null);
  };

  const onLogoutClick = async () => {
    await dispatch(logout());
  };

  if (!isMobile) {
    return null;
  }

  return (
    <div>
      <IconButton onClick={handleClick}>
        <IconMenu size='21' stroke='1.5' style={{ marginRight: 5 }} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleLogout}>
          <IconLogout size='21' stroke='1.5' style={{ marginRight: 5 }}
                      onClick={onLogoutClick} />
          Log Out
        </MenuItem>
      </Menu>
    </div>
  );
};

export default MobileMenu;
