import PageContainer from "../../shared/components/container/PageContainer";
import { Container, Grid, Paper, Stack, Typography } from "@mui/material";
import * as React from "react";
import tenant from "../../tenant";

const PaymentTermsAndConditions = ({}) => {
  return (
    <PageContainer title='Terms And Conditions'>
      <Grid container>
        <Container maxWidth='md'>
          <Paper elevation={3} style={{ padding: "2rem", marginTop: "2rem" }}>
            <Stack direction='column'
                   display='flex'
                   spacing={2}
                   sx={{ flexGrow: 1 }}>
              <Typography variant='h6' my={2} pb={1}>Payment Authorization Terms
                and Conditions</Typography>
              <Typography>
                Parties. In this One-Time Payment Authorization (this
                "Authorization"), the words "{tenant.name} Cash Lenders" "We," "Us"
                and "Our" mean {tenant.name} Cash Lenders and their affiliated
                companies, successors and assigns, and the words "You" and
                "Your" mean the person providing or designating the Payment
                Method (as defined below) information and any other person who
                is authorized to make purchases or obtain services from Us to be
                charged to the Payment Method.
              </Typography>
              <Typography>
                One-Time Payment Authorization. By manually signing (including a
                manual signature captured electronically), checking the box or
                clicking the button below or next to the link to this
                Authorization during Your transaction with Us today as Your
                electronic signature, You authorize Us to charge to the Payment
                Method (1) the amount of Your payment if You make a payment
                today, including any initial payment for a payment of your
                balance in installments or an ongoing service, (2) the total
                amount outstanding under the {tenant.name} Cash Lenders Account You
                designate today or (3) a different amount You specify today to
                be applied to the designated {tenant.name} Cash Lenders Account. All
                payments will be charged on the same day. Your Payment Method
                could be charged as early as today or on the date You specify
                for payment. A late fee may be applied to the applicable Account
                if You schedule a payment to occur after Your due date.
              </Typography>
              <Typography>
                If a charge to Your Payment Method is declined including for
                insufficient funds, We may re-submit the charge up to the number
                of times permitted by network rules, and We also reserve the
                right to undertake further collection action, including imposing
                costs and fees to the extent permitted by law, and to
                immediately start collection actions.
              </Typography>
              <Typography>
                Payment Method. "Payment Method" means the bank account, credit
                card, debit card or other payment method information (1) You
                provide to Us during Your transaction today including for
                purposes of this Authorization or (2) You previously provided to
                Us that You designate today to be charged under this
                Authorization.
                Revocation. You may revoke this Authorization by calling the
                customer support number on our website, which revocation will
                take effect if and when {tenant.name} Cash Lenders has had a
                reasonable opportunity to act on it before the payment is
                processed. You will be able to request revocations as to
                individual payments that have not been processed.
              </Typography>
              <Typography>
                General. You release {tenant.name} Cash Lenders from any and all
                claims arising from Your use of this Authorization. Please print
                or save to Your computer, tablet, smartphone or other device a
                copy of this Authorization for Your records.
              </Typography>
            </Stack>
          </Paper>
        </Container>
      </Grid>
    </PageContainer>
  );
};

export default PaymentTermsAndConditions;
