import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React from "react";
import WelcomeLoading from "./shared/WelcomeLoading";
import { formatDate } from "../../services/date-uitils";
import { formatPriceNumber } from "../../services/string-utils";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import tenant from "../../tenant";
import { Link } from "react-router-dom";

const WelcomeDetails = ({ user, loan }) => {

  const theme = useTheme();
  if (!user || !loan) {
    return (<WelcomeLoading />);
  }

  const nextPaymentDateStr = formatDate(loan.nextPaymentDate);
  const scheduledPaymentDateStr = formatDate(loan.scheduledPaymentDate);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Stack spacing={2}>
            <Typography variant='h4'>
              Welcome <span
              style={{ color: theme.palette.primary.main }}>{user.firstName} {user.lastName}</span>!
            </Typography>
            <Typography variant='h5' color='textPrimary'>
              {(user.open && user.good) &&
                <span>Great job! Your account is current.</span>}
              {(user.open && !user.good) &&
                <span>We show that your account is past due. Let's get this taken care of today.</span>}
              {(!user.open && user.good) &&
                <span>Great job! Our records show that you have no balance outstanding.</span>}
              {user.pendingReUp &&
                <span>Great job!  Your Account is in Good Standing.</span>}
            </Typography>
            <Typography variant='h6' color='textSecondary'>
              {(user.open && user.good && loan.scheduledPaymentDate === null) &&
                <span>
            Your next scheduled payment of ${formatPriceNumber(loan.nextPaymentAmount)} will be processed on the {nextPaymentDateStr}. <br />
            For substantial savings, we encourage you to pay your loan off early.
          </span>}
              {(user.open && user.good && loan.scheduledPaymentDate !== null) &&
                <span>
            Your next scheduled payment of ${formatPriceNumber(loan.scheduledPaymentAmount)} had begun processing and will draft from your bank account on {scheduledPaymentDateStr}. <br />
            For substantial savings, we encourage you to pay your loan off early.
          </span>}
            </Typography>
            {user.pendingReUp &&
              <>
                <Typography variant='h6' color='error'>
                  You have a Pending Application for a New Loan that is under
                  review.
                </Typography>
                <Box>
                  <Button variant='contained'
                          component={Link}
                          to={tenant.pendingLink + user.leadId}
                          color='error'
                          target='_blank'
                          rel='noopener noreferrer'
                          type='button'>
                    Check Application Status
                    <ArrowRightAltIcon size='21' stroke='1.5'
                                       style={{ marginRight: 5 }}>></ArrowRightAltIcon>
                  </Button>
                </Box>
                <Typography variant='h6' color='error'>
                  We will contact you shortly with a final loan decision.
                  <br />
                  If you have not done so already, please submit your bank
                  statement electronically through the bank link sent to you in
                  email and text.
                </Typography>
                <Typography variant='h6' color='error'>
                  We need your bank statement to confirm your income and to
                  qualify you for the new loan.
                </Typography>
                <Typography variant='h6' color='error'>
                  Your outstanding balance will not show on this page while your
                  application is pending.
                </Typography>
              </>}
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

export default WelcomeDetails;
