import { Skeleton, Stack } from "@mui/material";
import React from "react";
import DashboardCard from "../shared/DashboardCard";


const DashboardCardLoading = ({}) => {
  return (
    <DashboardCard title='Loading...'>
      <Stack direction='column'
             display='flex'
             spacing={1}
             sx={{ flexGrow: 1 }}>
        <Skeleton variant='h2' />
        <Skeleton variant='h2' />
        <Skeleton variant='h2' />
        <Skeleton variant='rectangle' height={40} />
      </Stack>
    </DashboardCard>
  );
};

export default DashboardCardLoading;
