import { Checkbox, FormControlLabel, Grid, TextField } from "@mui/material";
import InputMask from "react-input-mask";
import * as React from "react";
import valid from "card-validator";

const NewCreditCardRow = ({ details, onDetailsChange }) => {

  const handleCardExpireChange = (e) => {
    const value = e.target.value;
    onDetailsChange({ ...details, cardExpire: value.replace(/[^0-9.]/g, "") });
  };

  const handleSaveChange = (e) => {
    const value = e.target.checked;
    onDetailsChange({ ...details, save: value });
  };

  const handleCardNumberChange = (e) => {
    const value = e.target.value;
    onDetailsChange({
      ...details,
      cardNumber: value.replace(/[^0-9.]/g, ""),
      cardType: determineCardType(value)
    });
  };

  const handleInputChange = (name, e) => {
    onDetailsChange({ ...details, [name]: e.target.value });
  };

  const determineCardType = (number) => {
    const digits = number.replace(/\D+/g, "");
    if (digits.startsWith("4")) {
      return "visa";
    } else if (digits.startsWith("5")) {
      return "mastercard";
    } else if (digits.startsWith("34") || digits.startsWith("37")) {
      return "american_express";
    } else if (digits.startsWith("6011") ||
      (digits.startsWith("64") && parseInt(digits.substring(2, 3)) >= 4 && parseInt(digits.substring(2, 3)) <= 9) ||
      digits.startsWith("65")) {
      return "discover";
    }
    return null;
  };

  const validateCardNumber = (event) => {
    const cardNumberValidate = valid.number(event.target.value);
    if (cardNumberValidate.isPotentiallyValid && cardNumberValidate.isValid) {
      return event.target.setCustomValidity("");
    }
    return event.target.setCustomValidity("Credit card number is invalid.");
  };

  const validateCardExpire = (event) => {
    const cardExpireValidate = valid.expirationDate(event.target.value);
    if (cardExpireValidate.isPotentiallyValid && cardExpireValidate.isValid) {
      return event.target.setCustomValidity("");
    }
    return event.target.setCustomValidity("Credit card expire date is invalid.");
  };

  const resetValidation = (event) => {
    event.target.setCustomValidity("");
  };

  return (
    <Grid container spacing={3} p={1}>
      <Grid item xs={12} md={6}>
        <InputMask mask='9999 9999 9999 9999' maskChar=' '
                   onBlur={validateCardNumber}
                   onChange={handleCardNumberChange}>
          {() =>
            <TextField
              onInput={resetValidation}
              onClick={resetValidation}
              required
              id='cardNumber'
              label='Card number'
              fullWidth
            />
          }
        </InputMask>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          required
          id='cardName'
          onChange={(e) => handleInputChange("cardName", e)}
          label='Name on card'
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <InputMask mask='99/99'
                   maskChar='_'
                   maskPlaceholder='MM/YY'
                   onBlur={validateCardExpire}
                   onChange={handleCardExpireChange}>
          {() =>
            <TextField
              required
              onInput={resetValidation}
              onClick={resetValidation}
              id='expDate'
              label='Expires (MM/YY)'
              fullWidth
            />
          }
        </InputMask>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          required
          id='cvv'
          type='password'
          label='Security code'
          placeholder='3 digits'
          fullWidth
          inputProps={{ minLength: 3, maxLength: 3, pattern: "[0-9]*" }}
          onChange={(e) => handleInputChange("cardCvv", e)}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <TextField
          required
          id='email'
          onChange={(e) => handleInputChange("cardEmail", e)}
          label='Email'
          helperText='Email Payment Correspondence will be sent to you to let you know actual payment statuses.'
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={<Checkbox color='secondary'
                             onChange={handleSaveChange}
                             checked={details.save} />}
          label='Save this payment method for furture use'
        />
      </Grid>
    </Grid>
  );
};

export default NewCreditCardRow;
