const initialState = {
  isAuthenticated: localStorage.getItem("authToken") !== null,
  account: null
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN_START":
      return { ...state, account: null };
    case "LOGIN_SUCCESS":
      return {
        ...state,
        isAuthenticated: true,
        account: action.payload
      };
    case "LOGOUT":
      return {
        isAuthenticated: false,
        account: null
      };
    default:
      return state;
  }
};

export default authReducer;
