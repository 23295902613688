import React, { useEffect } from "react";
import { Box, Container, styled } from "@mui/material";
import { Outlet } from "react-router-dom";

import Header from "./header/Header";
import Footer from "./footer/Footer";
import { connect, useDispatch } from "react-redux";
import { loadFeatureFlags } from "../../../actions/analyticsActions";

const MainWrapper = styled("div")(() => ({
  display: "flex",
  minHeight: "100vh",
  width: "100%"
}));

const PageWrapper = styled("div")(() => ({
  display: "flex",
  flexGrow: 1,
  paddingBottom: "60px",
  flexDirection: "column",
  zIndex: 1,
  backgroundColor: "transparent"
}));

const mapStateToProps = (state) => ({
  user: state.auth.account,
  livechatEnabled: state.analytics?.featureFlags?.livechatEnabled
});
const FullLayout = ({ user, livechatEnabled }) => {

  const dispatch = useDispatch();

  useEffect(() => {
    if (!livechatEnabled) {
      dispatch(loadFeatureFlags());
    }
  }, [dispatch, livechatEnabled]);

  useEffect(() => {
    if (window.RocketChat && user) {
      window.RocketChat(function() {
        if (livechatEnabled?.value === true && process.env.REACT_APP_LIVECHAT_ENABLE === "1") {
          this.registerGuest({
            token: user.customerId,
            name: user.firstName + " " + user.lastName,
            department: JSON.parse(livechatEnabled.payload) ?? process.env.REACT_APP_LIVECHAT_DEPARTMENT,
            email: user.email,
            customFields: [
              { key: "loanId", value: `${user.loanId}`, overwrite: true },
              { key: "customerId", value: user.customerId, overwrite: true }
            ]
          });
          this.initialize({
            language: "en",
            guestToken: user.customerId
          });
          this.showWidget();
        } else {
          this.hideWidget();
        }
      });
    }
  }, [user, livechatEnabled]);

  return (
    <MainWrapper
      className='mainwrapper'
    >
      {/* ------------------------------------------- */}
      {/* Main Wrapper */}
      {/* ------------------------------------------- */}
      <PageWrapper
        className='page-wrapper'
      >
        {/* ------------------------------------------- */}
        {/* Header */}
        {/* ------------------------------------------- */}
        <Header />
        {/* ------------------------------------------- */}
        {/* PageContent */}
        {/* ------------------------------------------- */}
        <Container sx={{
          paddingTop: "20px",
          maxWidth: "80wv"
        }}
        >
          {/* ------------------------------------------- */}
          {/* Page Route */}
          {/* ------------------------------------------- */}
          <Box sx={{ minHeight: "calc(100vh - 170px)" }}>
            <Outlet />
          </Box>
          {/* ------------------------------------------- */}
          {/* End Page */}
          {/* ------------------------------------------- */}
          <Footer />
        </Container>

      </PageWrapper>
    </MainWrapper>
  );
};

export default connect(mapStateToProps)(FullLayout);
