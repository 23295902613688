export function capitalizeFirstLetter(string) {
  if (!string) {
    return "";
  }
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

export function formatPriceNumber(number) {
  if (number === undefined) {
    return "";
  }
  return (+number).toFixed(2);
}
