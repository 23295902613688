import {
  Card,
  CardHeader,
  Chip,
  FormControlLabel,
  IconButton,
  Radio,
  Stack,
  Typography
} from "@mui/material";
import visa from "../../assets/icons/cc/visa.svg";
import mastercard from "../../assets/icons/cc/mastercard.svg";
import american_express from "../../assets/icons/cc/american_express.svg";
import discovery from "../../assets/icons/cc/discovery.svg";
import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { IconDotsVertical } from "@tabler/icons";


const ExistingCreditCardRow = ({
                                 cardId,
                                 cardNumber,
                                 cardType,
                                 isPrimary,
                                 onRemove,
                                 onMakePrimary
                               }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onRemoveClick = (e) => {
    handleClose();
    onRemove(cardId);
  };
  const onMakePrimaryClick = (e) => {
    handleClose();
    onMakePrimary(cardId);
  };

  return (
    <Card variant='outlined' sx={{ marginTop: 1, marginBottom: 1 }}>
      <CardHeader title={
        <Stack direction='row'
               display='flex'
               alignItems='center'
               justifyContent='space-between'>
          <FormControlLabel
            value={cardId}
            control={<Radio />}
            label={
              <Stack direction='row'
                     display='flex'
                     alignItems='center'
                     spacing={2}
                     justifyContent='start'>
                {cardType === "Visa" &&
                  <img src={visa} alt='Visa' height={35} />}
                {cardType === "Mastercard" &&
                  <img src={mastercard} alt='Mastercard' height={35} />}
                {cardType === "AMEX" &&
                  <img src={american_express} alt='Amex' height={35} />}
                {cardType === "Discover" &&
                  <img src={discovery} alt='Discover' height={35} />}
                <Typography variant='h6'>{cardNumber}</Typography>
                {isPrimary && <Chip label='Primary' size='small' />}
              </Stack>
            }
          />
          {!isPrimary && <>
            <IconButton
              aria-label='more'
              aria-controls='long-menu'
              aria-haspopup='true'
              onClick={handleClick}
            >
              <IconDotsVertical />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={onRemoveClick}> Delete</MenuItem>
              <MenuItem onClick={onMakePrimaryClick}> Make primary</MenuItem>
            </Menu>
          </>}
        </Stack>
      } />
    </Card>
  );
};
export default ExistingCreditCardRow;
