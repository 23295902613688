import apiService from "../services/apiService";

export const loadPaymentData = () => async dispatch => {
  dispatch({ type: "PAYMENT_LOAD" });
  try {
    const billingResponse = await apiService.getBillingInfo();
    const paymentResponse = await apiService.getPaymentInfo();
    dispatch({
      type: "PAYMENT_SUCCESS",
      payload: {
        billing: billingResponse.data,
        paymentMethods: paymentResponse.data
      }
    });
  } catch (e) {
    console.log("Could not get billing/payment info", e);
  }
};

export const removePayment = (id) => async dispatch => {
  try {
    const paymentResponse = await apiService.removePaymentInfo(id);
    dispatch({
      type: "PAYMENT_REMOVE",
      payload: paymentResponse.data
    });
  } catch (e) {
    console.log("Could not remove payment info", e);
  }
};

export const updatePrimaryPayment = (id) => async dispatch => {
  try {
    const paymentResponse = await apiService.updatePrimaryPayment(id);
    dispatch({
      type: "PAYMENT_UPDATE_PRIMARY",
      payload: paymentResponse.data
    });
  } catch (e) {
    console.log("Could not update payment method", e);
  }
};

export const updateBilling = (billing) => async dispatch => {
  try {
    await apiService.updateBillingInfo(billing);
    dispatch({
      type: "PAYMENT_BILLING_UPDATE",
      payload: billing
    });
  } catch (e) {
    console.log("Could not update billing info", e);
  }
};
