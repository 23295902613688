import React from "react";
import { AppBar, Toolbar, Typography } from "@mui/material";
import packageJson from "../../../../../package.json";
import tenant from "../../../../tenant";

const Footer = ({}) => {
  const year = new Date().getFullYear();

  return (
    <AppBar position='fixed' color='grey' sx={{ top: "auto", bottom: 0 }}>
      <Toolbar style={{ display: "flex", justifyContent: "center" }}>
        <Typography variant='h7'>Copyright @ {year} {tenant.name} Cash
          Lenders</Typography>
      </Toolbar>
    </AppBar>
  );
};

export default Footer;
