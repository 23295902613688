import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";

// Reducers
import authReducer from "../reducers/authReducer";
import loanReducer from "../reducers/loanReducers";
import paymentReducer from "../reducers/paymentReducer";
import activityReducer from "../reducers/activityReducer";
import analyticsReducer from "../reducers/analyticsReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  loan: loanReducer,
  payment: paymentReducer,
  activity: activityReducer,
  analytics: analyticsReducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk)
});

export default store;
