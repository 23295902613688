const initialState = {
  data: null
};

const activityReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ACTIVITY_LOAD":
      return { ...state, data: null };
    case "ACTIVITY_SUCCESS":
      return { ...state, data: action.payload };
    case "LOGOUT":
      return initialState;
    default:
      return state;
  }
};

export default activityReducer;
