import { Container, Paper, Skeleton, Stack } from "@mui/material";
import * as React from "react";

const StepLoading = () => {
  return (
    <Container maxWidth='md'>
      <Paper elevation={3} style={{ padding: "2rem", marginTop: "2rem" }}>
        <Stack direction='column'
               display='flex'
               spacing={1}
               sx={{ flexGrow: 1 }}>
          <Skeleton variant='h2' />
          <Skeleton variant='h2' />
          <Skeleton variant='h2' />
          <Skeleton variant='rectangle' height={40} />
        </Stack>
      </Paper>
    </Container>
  );
};
export default StepLoading;
