import moment from "moment/moment";


export function formatDate(date) {
  if (!date) {
    return "";
  }
  return moment(date).format("MM/DD/yyyy");
}

export function areDatesEqual(date1, date2) {
  const d1 = new Date(date1);
  const d2 = new Date(date2);
  d1.setHours(0, 0, 0, 0);
  d2.setHours(0, 0, 0, 0);
  return d1.getTime() === d2.getTime();
}
