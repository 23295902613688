import freedom from './environment/freedom.json';
import liberty from './environment/liberty.json';


const getConfig = () => {
  switch (process.env.REACT_APP_TENANT) {
    case 'freedom':
      return freedom;
    case 'liberty':
      return liberty;
    default:
      return freedom;
  }
};
const tenant = getConfig();

export default tenant;
