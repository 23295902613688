import visa from "../../assets/icons/cc/visa.svg";
import mastercard from "../../assets/icons/cc/mastercard.svg";
import american_express from "../../assets/icons/cc/american_express.svg";
import discovery from "../../assets/icons/cc/discovery.svg";
import { Stack } from "@mui/material";
import * as React from "react";

const SelectedCardTypeRow = ({ cardType }) => {
  return (
    <Stack direction='row'
           display='flex'
           alignItems='center'>
      <img src={visa} alt='Visa' height={35}
           style={{ opacity: cardType === "visa" ? 1.0 : 0.3 }} />
      <img src={mastercard} alt='MC' height={35}
           style={{ opacity: cardType === "mastercard" ? 1.0 : 0.3 }} />
      <img src={american_express} alt='AE' height={35}
           style={{ opacity: cardType === "american_express" ? 1.0 : 0.3 }} />
      <img src={discovery} alt='Discover' height={35}
           style={{ opacity: cardType === "discover" ? 1.0 : 0.3 }} />
    </Stack>
  );
};

export default SelectedCardTypeRow;
