import moment from "moment";
import apiService from "../services/apiService";

export const loadLoanData = () => async dispatch => {
  const date = moment().format("yyyy-MM-DD");
  try {
    dispatch({
      type: "LOAN_LOAD"
    });
    const response = await apiService.getLoanData(date);
    dispatch({
      type: "LOAN_SUCCESS",
      payload: response.data
    });
  } catch (e) {
    console.log("Could not get loan information", e);
  }
};

export const loadApplicationData = () => async dispatch => {
  try {
    dispatch({
      type: "APPLICATION_DATA_LOAD"
    });
    const response = await apiService.getCustomerData();
    dispatch({
      type: "APPLICATION_DATA_SUCCESS",
      payload: response.data
    });
  } catch (e) {
    console.log("Could not load application data", e);
  }
};
