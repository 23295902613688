import { analyticsTracker } from "../services/analytics";

export const loadFeatureFlags = () => dispatch => {
  dispatch({ type: "FEATURE_FLAGS_LOAD" });
  try {
    analyticsTracker.onFlagsLoad((flags) => {
      const flagsObj = {};
      for (let flag of flags) {
        flagsObj[flag.key] = flag;
      }
      dispatch({
        type: "FEATURE_FLAGS_SUCCESS",
        payload: flagsObj
      });
    });
  } catch (ignored) {
    dispatch({ type: "FEATURE_FLAGS_SUCCESS", payload: {} });
  }
};
