import {
  Box,
  Button,
  Container,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Typography
} from "@mui/material";
import * as React from "react";

const ApplyNewLoanStep1 = ({ amountToBorrow, onComplete }) => {
  const [amount, setAmount] = React.useState(amountToBorrow);

  const handleAmountChange = (event) => {
    setAmount(event.target.value);
  };

  const handleNext = () => {
    onComplete(amount);
  };

  const handleCancel = () => {
    window.history.back();
  };

  return (
    <Container maxWidth='sm'>
      <Paper elevation={3} style={{ padding: "2rem", marginTop: "2rem" }}>
        <Stack spacing={2}>
          <Typography variant='h5' my={2} pb={1}>New loan
            application</Typography>
          <FormControl fullWidth>
            <InputLabel>How much?</InputLabel>
            <Select
              value={amount}
              startAdornment={<InputAdornment
                position='start'>$</InputAdornment>}
              label='Select amount'
              onChange={handleAmountChange}>
              <MenuItem value={200}>200</MenuItem>
              <MenuItem value={250}>250</MenuItem>
              <MenuItem value={300}>300</MenuItem>
              <MenuItem value={350}>350</MenuItem>
              <MenuItem value={400}>400</MenuItem>
              <MenuItem value={450}>450</MenuItem>
              <MenuItem value={500}>500</MenuItem>
              <MenuItem value={600}>600</MenuItem>
              <MenuItem value={700}>700</MenuItem>
              <MenuItem value={800}>800</MenuItem>
              <MenuItem value={900}>900</MenuItem>
              <MenuItem value={1000}>1000</MenuItem>
            </Select>
          </FormControl>
        </Stack>
        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
          <Button
            color='inherit'
            onClick={handleCancel}
            sx={{ mr: 1 }}
          >
            Back
          </Button>
          <Box sx={{ flex: "1 1 auto" }} />
          <Button disabled={amount === 0}
                  onClick={handleNext}>Next</Button>
        </Box>
      </Paper>
    </Container>
  );
};

export default ApplyNewLoanStep1;
