const initialState = {
  data: null,
  applicationData: null
};

const loanReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOAN_LOAD":
      return {
        ...state,
        data: null
      };
    case "LOAN_SUCCESS":
      return {
        ...state,
        data: action.payload
      };
    case "APPLICATION_DATA_LOAD":
      return {
        ...state,
        applicationData: null
      };
    case "APPLICATION_DATA_SUCCESS":
      return {
        ...state,
        applicationData: action.payload
      };
    case "LOGOUT":
      return initialState;
    default:
      return state;
  }
};

export default loanReducer;
