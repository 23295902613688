import React from "react";
import { AppBar, Box, Button, Stack, styled, Toolbar } from "@mui/material";
import PropTypes from "prop-types";
import { IconDashboard, IconLogout } from "@tabler/icons";
import Logo from "../../../components/logo/Logo";
import MobileMenu from "./MobileMenu";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDispatch } from "react-redux";
import { logout } from "../../../../actions/authActions";
import { Link } from "react-router-dom";

const Header = ({}) => {

  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down("sm"));

  const onLogoutClick = async () => {
    dispatch(logout());
  };

  const AppBarStyled = styled(AppBar)(({ theme }) => ({
    boxShadow: "none",
    background: theme.palette.background.paper,
    justifyContent: "center",
    backdropFilter: "blur(4px)",
    [theme.breakpoints.up("lg")]: {
      minHeight: "70px"
    }
  }));
  const ToolbarStyled = styled(Toolbar)(({ theme }) => ({
    width: "100%",
    color: theme.palette.text.secondary
  }));

  return (
    <AppBarStyled position='sticky' color='default'
                  sx={{
                    paddingLeft: { sm: 5, md: 10 },
                    paddingRight: { sm: 5, md: 10 }
                  }}>
      <ToolbarStyled>
        <Logo />
        <Stack spacing={1} direction='row' alignItems='start' px={2}>
          <Button variant='outlined'
                  color='primary'
                  component={Link}
                  to='/dashboard'
                  type='button'>
            <IconDashboard size='21' stroke='1.5'
                           style={{ marginRight: 5 }}>></IconDashboard>
            Dashboard
          </Button>
        </Stack>
        <Box flexGrow={1} />
        {!isMobile && <Stack spacing={1}
                             direction='row'
                             alignItems='center'>
          <Button variant='contained'
                  color='primary'
                  onClick={onLogoutClick}
                  type='button'>
            <IconLogout size='21' stroke='1.5'
                        style={{ marginRight: 5 }}>></IconLogout>
            Logout
          </Button>
        </Stack>}
        <MobileMenu />
      </ToolbarStyled>
    </AppBarStyled>
  );
};

Header.propTypes = {
  sx: PropTypes.object
};

export default Header;
