import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import PageContainer from "../../shared/components/container/PageContainer";
import { LoadingButton } from "@mui/lab";
import { NavLink, useNavigate } from "react-router-dom";
import apiService from "../../services/apiService";
import { enqueueSnackbar } from "notistack";

const ForgotEmail = () => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);
  const [email, setEmail] = useState("");
  const [formData, setFormData] = useState({
    ssn: ""
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const result = await apiService.getEmailBySsn(formData);
      setEmail(result.data.email);
      setSubmitted(true);
    } catch (e) {
      if (e.status === 404) {
        enqueueSnackbar("SSN not found.", { variant: "error" });
      } else if (e.status === 403) {
        enqueueSnackbar("Max requests limit reached. Please try again in 60 minutes.", { variant: "error" });
      } else {
        enqueueSnackbar("Could not submit callback request. Please try again later or contact support.", { variant: "error" });
        console.log("Could not submit form", e);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleLoginWithDifferentEmail = async () => {
    navigate("/auth/login", { replace: true });
  };

  return (
    <>
      <PageContainer title='Login'>
        <Box
          sx={{
            position: "relative",
            "&:before": {
              content: "\"\"",
              background: "radial-gradient(#d2f1df, #d3d7fa, #bad8f4)",
              backgroundSize: "400% 400%",
              animation: "gradient 15s ease infinite",
              position: "absolute",
              height: "100%",
              width: "100%",
              opacity: "0.3"
            }
          }}
        >
          <Grid container spacing={0} justifyContent='center'
                sx={{ height: "100vh" }}>
            <Grid
              item
              xs={12}
              sm={12}
              lg={4}
              xl={3}
              display='flex'
              justifyContent='center'
              alignItems='center'
            >
              {!isSubmitted && <Card elevation={9}
                                     sx={{
                                       p: 4,
                                       zIndex: 1,
                                       width: "100%",
                                       maxWidth: "500px"
                                     }}>
                <form onSubmit={handleSubmit}>
                  <Box display='flex' alignItems='center'
                       justifyContent='center'>
                    <Typography textAlign='center'>If you feel you have an
                      account and are not able to
                      login with the combination of email and last four of your
                      Social Security Number, we can locate your accountant
                      using your full Social Security Number</Typography>
                  </Box>
                  <Stack spacing={3} my={3}>
                    <TextField id='ssn'
                               label='Enter Social Security Number'
                               type='text'
                               variant='outlined'
                               fullWidth
                               required
                               name='ssn'
                               value={formData.ssn}
                               onChange={handleInputChange}
                               disabled={isLoading}
                               inputProps={{
                                 minLength: 9,
                                 maxLength: 9,
                                 pattern: "[0-9]*"
                               }}
                    />
                    <NavLink to='/auth/login'>Back to login</NavLink>
                  </Stack>
                  <Box>
                    <LoadingButton
                      loading={isLoading}
                      color='primary'
                      variant='contained'
                      size='large'
                      fullWidth
                      type='submit'
                    >
                      Submit
                    </LoadingButton>
                  </Box>
                </form>
              </Card>}
              {isSubmitted &&
                <Card elevation={9}
                      sx={{
                        p: 4,
                        zIndex: 1,
                        width: "100%",
                        maxWidth: "500px"
                      }}>
                  <CardContent>
                    <Typography variant='h6' textAlign='center' mb={4}>We've
                      located an account with this SSN</Typography>
                    <Button variant='contained' color='primary'
                            onClick={handleLoginWithDifferentEmail}
                            fullWidth
                            style={{ textTransform: "none" }}>
                      Log in with {email}
                    </Button>
                  </CardContent>
                </Card>
              }
            </Grid>
          </Grid>
        </Box>
      </PageContainer>
    </>
  );
};

export default ForgotEmail;
