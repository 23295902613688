import {
  Box,
  Button,
  Fab,
  Grid,
  Paper,
  Stack,
  Typography
} from "@mui/material";
import { IconX } from "@tabler/icons";
import { Link } from "react-router-dom";
import React from "react";
import moment from "moment-timezone";

const PaymentCompleteError = ({
                                errorCode,
                                errorTitle,
                                errorDescription,
                                payment
                              }) => {
  return (
    <>
      <Grid item xs={12} sm={8} order={{ xs: 2, sm: 1 }}>
        <Paper elevation={3} style={{ padding: "2rem", marginTop: "2rem" }}>
          <Stack display='flex'
                 spacing={2}
                 direction='column'
                 justifyContent='stretch'
                 alignItems='center'>
            <Fab color='error' size='medium'>
              <IconX width={24} />
            </Fab>
            <Typography variant='h5' align='center'>{errorTitle}</Typography>
            <Typography variant='h7' align='center'>Error
              code: {errorCode}</Typography>
            <Typography variant='h7'
                        align='center'>{errorDescription}</Typography>
            <Box height={15} />
            <Button component={Link} to='/dashboard' variant='contained'
                    color='info'>Return Home</Button>
          </Stack>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={4} order={{ xs: 1, sm: 2 }}>
        <Paper elevation={3} style={{ padding: "2rem", marginTop: "2rem" }}>
          <Typography variant='h6' mb={2}>
            Payment attempt summary
          </Typography>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Typography variant='body2'>
                Date of payment:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='body2'>
                {moment.utc(payment.paymentDate).tz(moment.tz.guess()).format("MMMM DD, yyyy")}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='body2'>
                Payment Time:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='body2'>
                {moment.utc(payment.paymentDate).tz(moment.tz.guess()).format("HH:mm z")}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='body2'>
                Amount:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='body2'>
                ${payment.amount.toFixed(2)}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='body2'>
                Payment Method:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='body2'>
                Debit card
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='body2'>
                Card number:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='body2'>
                {payment.cardNumber}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='body2'>
                Status:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='body2'>
                {errorTitle}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </>
  );
};

export default PaymentCompleteError;
