import { Link } from "react-router-dom";
import LogoFreedom from '../../../assets/images/logo-freedom.png';
import LogoLiberty from '../../../assets/images/logo-liberty.png';
import { styled } from "@mui/material";
import tenant from "../../../tenant";

const LinkStyled = styled(Link)(() => ({
  height: "54px",
  width: "148px",
  overflow: "hidden",
  display: "block"
}));

const Logo = () => {
  return (
    <LinkStyled to='/'>
      {tenant.id === 'FCL' && <img src={LogoFreedom} alt='not found' height={43} />}
      {tenant.id === 'LCL' && <img src={LogoLiberty} alt='not found' height={52} />}
    </LinkStyled>
  );
};

export default Logo;
