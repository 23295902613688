import { Paper, Stack, Typography } from "@mui/material";
import * as React from "react";

const PaymentAmount = ({ amount }) => {
  if (!amount) {
    return null;
  }
  return (
    <Paper elevation={3} style={{
      paddingLeft: "1rem",
      paddingRight: "1rem",
      paddingTop: "2rem",
      paddingBottom: "2rem",
      marginTop: "2rem"
    }}>
      <Stack direction='column'
             display='flex'
             spacing={1}
             sx={{ flexGrow: 1 }}>
        <Stack spacing={2}
               display='flex'
               direction='row'
               justifyContent='space-between'
               alignItems='center'
               sx={{ flexGrow: 1 }}>
          <Typography variant='h6'>Payment amount:</Typography>
          <Typography variant='h6'>${amount.toFixed(2)}</Typography>
        </Stack>
      </Stack>
    </Paper>
  );
};

export default PaymentAmount;
