import {
  Box,
  Button,
  Container,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  Typography
} from "@mui/material";
import * as React from "react";
import apiService from "../../services/apiService";
import ExistingCreditCardRow from "./ExistingCreditCardRow";
import NewCreditCardRow from "./NewCreditCardRow";
import ConfirmationDialog from "../../shared/components/ConfirmationDialog";
import { useDispatch } from "react-redux";
import {
  removePayment,
  updatePrimaryPayment
} from "../../actions/paymentActions";
import SelectedCardTypeRow from "./SelectedCardTypeRow";
import PaymentAmount from "./PaymentAmount";
import { Link } from "react-router-dom";
import { LoadingButton } from "@mui/lab";

const PaymentStep3 = ({
                        paymentType,
                        billing,
                        paymentMethods,
                        amountToPay,
                        onBack
                      }) => {

  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [removePaymentMethod, setRemovePaymentMethod] = React.useState();
  const [newCardDetails, setNewCardDetails] = React.useState({
    cardNumber: "",
    cardName: "",
    cardExpire: "",
    cardType: "",
    cardCvv: "",
    cardEmail: "",
    save: false
  });
  const [selectedPaymentMethod, setSelectedPaymentMethod] = React.useState(paymentMethods.length > 0 ? paymentMethods.find((method) => method.primary).id : "new");
  const [selectedCard, setSelectedCard] = React.useState(
    paymentMethods.length > 0 ? paymentMethods.find((method) => method.primary) : undefined
  );
  const [formData, setFormData] = React.useState([]);

  const fetchData = async () => {
    try {
      const response = await apiService.getPaymentFormData(amountToPay);
      const fields = Object.entries(response.data).map(([key, value]) => {
        return { key: key, value: value };
      });
      setFormData(fields);
    } catch (e) {
      console.log("Could not load form data", e);
    }
  };

  React.useEffect(() => {
    fetchData();
  }, [amountToPay]);

  const handleSelectChange = (event) => {
    const methodId = event.target.value;
    setSelectedPaymentMethod(methodId);
    if (methodId !== "new") {
      const methodIdNum = +methodId;
      const card = paymentMethods.find(method => method.id === methodIdNum);
      setSelectedCard(card);
    } else {
      setSelectedCard(null);
    }
  };

  const onDetailsChange = (details) => {
    setNewCardDetails(details);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    e.target.submit();
  };
  const onRemovePayment = (id) => {
    const method = paymentMethods.find(m => m.id === id);
    setRemovePaymentMethod(method);
  };

  const onRemovePaymentConfirm = () => {
    const removeId = removePaymentMethod.id;
    dispatch(removePayment(removeId));
    setRemovePaymentMethod(undefined);
    if (selectedPaymentMethod === removeId) {
      setSelectedPaymentMethod("new");
      setSelectedCard(null);
    }
  };
  const onRemovePaymentClose = () => {
    setRemovePaymentMethod(undefined);
  };

  const onMakePrimaryPayment = (id) => {
    dispatch(updatePrimaryPayment(id));
  };

  return (
    <Container maxWidth='md'>
      <Grid container>
        <Grid item sm={8} xs={12} padding={1}>
          <Paper elevation={3} style={{ padding: "2rem", marginTop: "2rem" }}>
            <Stack direction='row' display='flex' justifyContent='space-between'
                   alignItems='ceneter'>
              <Typography variant='h5' my={2}>Payment method</Typography>
              {paymentMethods.length === 0 &&
                <SelectedCardTypeRow cardType={newCardDetails.cardType} />}
            </Stack>
            <form action={process.env.REACT_APP_PAYMENT_GATEWAY_URL}
                  method='POST'
                  autoComplete='off'
                  onSubmit={handleSubmit}>
              {formData.map((entry) => {
                return (<input type='hidden'
                               key={entry.key}
                               name={entry.key}
                               value={entry.value} />);
              })}
              <input type='hidden' name='UMstreet'
                     value={billing.streetAddress} />
              <input type='hidden' name='UMzip' value={billing.zipCode} />
              <input type='hidden' name='UMbillfname'
                     value={billing.firstName} />
              <input type='hidden' name='UMbilllname'
                     value={billing.lastName} />
              <input type='hidden' name='UMbillstreet'
                     value={billing.streetAddress} />
              <input type='hidden' name='UMbillcity' value={billing.city} />
              <input type='hidden' name='UMbillstate' value={billing.state} />
              <input type='hidden' name='UMbillzip' value={billing.zipCode} />
              <input type='hidden' name='UMcustom1' value={paymentType} />
              {selectedPaymentMethod === "new" &&
                (
                  <>
                    <input type='hidden' name='UMcard'
                           value={newCardDetails.cardNumber} />
                    <input type='hidden' name='UMname'
                           value={newCardDetails.cardName} />
                    <input type='hidden' name='UMexpir'
                           value={newCardDetails.cardExpire} />
                    <input type='hidden' name='UMcvv2'
                           value={newCardDetails.cardCvv} />
                    <input type='hidden' name='UMcustemail'
                           value={newCardDetails.cardEmail} />
                    <input type='hidden' name='UMsaveCard'
                           value={newCardDetails.save.toString()} />
                  </>
                )
              }
              {(selectedPaymentMethod !== "new" && selectedCard) &&
                <>
                  <input type='hidden' name='UMexpir' value='0000' />
                  <input type='hidden' name='UMcard'
                         value={selectedCard.cardRef} />
                  <input type='hidden' name='UMname'
                         value={selectedCard.cardHolder} />
                  <input type='hidden' name='UMcustemail'
                         value={selectedCard.email} />
                </>
              }
              <RadioGroup value={selectedPaymentMethod}
                          onChange={handleSelectChange}>
                {paymentMethods.map(method => (
                  <ExistingCreditCardRow key={method.id}
                                         cardId={method.id}
                                         cardNumber={method.cardNumber}
                                         cardType={method.cardType}
                                         isPrimary={method.primary}
                                         onMakePrimary={onMakePrimaryPayment}
                                         onRemove={onRemovePayment} />
                ))
                }
                {paymentMethods.length > 0 && <FormControlLabel
                  sx={{
                    marginLeft: 1,
                    marginRight: 1,
                    marginTop: 2,
                    "& .MuiFormControlLabel-label": {
                      width: "100%"
                    }
                  }}
                  value='new'
                  control={<Radio />}
                  label={
                    <Stack direction='row'
                           display='flex'
                           justifyContent='space-between'
                           alignItems='center'
                    >
                      <span>Add new method</span>
                      {selectedPaymentMethod === "new" && <SelectedCardTypeRow
                        cardType={newCardDetails.cardType} />}
                    </Stack>
                  }
                />}
                {selectedPaymentMethod === "new" &&
                  <NewCreditCardRow details={newCardDetails}
                                    onDetailsChange={onDetailsChange} />
                }
              </RadioGroup>
              <Box mt={2} pl={1}
                   sx={{ display: "flex", flexDirection: "column" }}>
                <Typography variant='caption'>
                  By selecting Submit, I agree to the Payment Authorization
                  Terms &
                  Conditions.
                </Typography>
                <Typography variant='caption'>
                  <Link to='/payment-terms' target='_blank'
                        rel='noopener'>Read terms and conditions
                  </Link>
                </Typography>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Button
                  color='inherit'
                  disabled={loading}
                  onClick={onBack}
                  sx={{ mr: 1 }}
                >
                  Back
                </Button>
                <Box sx={{ flex: "1 1 auto" }} />
                <LoadingButton type='submit'
                               variant='contained'
                               color='primary'
                               loading={loading}>Submit</LoadingButton>
              </Box>
            </form>
          </Paper>
        </Grid>
        <Grid item sm={4} xs={12} padding={1}>
          <PaymentAmount amount={amountToPay} />
        </Grid>
      </Grid>
      <ConfirmationDialog isOpen={removePaymentMethod !== undefined}
                          title='Delete confirmation'
                          text={`Are you sure you want to delete payment method: ${removePaymentMethod?.cardNumber}`}
                          onConfirm={onRemovePaymentConfirm}
                          onClose={onRemovePaymentClose}
      />
    </Container>
  );
};

export default PaymentStep3;
