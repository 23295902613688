import { Container, Grid, Paper, Stack, Typography } from "@mui/material";
import PageContainer from "../../shared/components/container/PageContainer";
import * as React from "react";

const Privacy = ({}) => {
  return (
    <PageContainer title='Privacy Policy'>
      <Grid container>
        <Container maxWidth='md'>
          <Paper elevation={3} style={{ padding: "2rem", marginTop: "2rem" }}>
            <Stack direction='column'
                   display='flex'
                   spacing={2}
                   sx={{ flexGrow: 1 }}>
              <Typography variant='h6' my={2} pb={1}>Privacy</Typography>
            </Stack>
          </Paper>
        </Container>
      </Grid>
    </PageContainer>
  );
};

export default Privacy;
