const initialState = {
  billing: null,
  paymentMethods: null
};

const paymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case "PAYMENT_LOAD":
      return {
        ...state,
        billing: null,
        paymentMethods: null
      };
    case "PAYMENT_SUCCESS":
      return {
        ...state,
        ...action.payload
      };
    case "PAYMENT_BILLING_UPDATE":
      return {
        ...state,
        billing: action.payload
      };
    case "PAYMENT_REMOVE":
    case "PAYMENT_UPDATE_PRIMARY":
      return { ...state, paymentMethods: action.payload };
    case "LOGOUT":
      return initialState;
    default:
      return state;
  }
};

export default paymentReducer;
