import { useLocation } from "react-router-dom";
import PageContainer from "../../shared/components/container/PageContainer";
import { Grid } from "@mui/material";
import React from "react";
import apiService from "../../services/apiService";
import PaymentCompleteLoading from "./PaymentCompleteLoading";
import PaymentCompleteSuccess from "./PaymentCompleteSuccess";
import PaymentCompleteError from "./PaymentCompleteError";


const PaymentComplete = ({}) => {

  const [payment, setPayment] = React.useState(null);

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const UMresult = queryParams.get("UMresult");
  const UMstatus = queryParams.get("UMstatus");
  const UMerror = queryParams.get("UMerror");
  const UMerrorCode = queryParams.get("UMerrorcode");

  const submitData = async () => {
    setPayment(null);
    try {
      const result = {};
      for (let [key, value] of queryParams.entries()) {
        result[key] = value;
      }
      const paymentResponse = await apiService.completePayment(result);
      setPayment(paymentResponse.data);
    } catch (e) {
      console.log("Could not complete payment results", e);
    }
  };

  React.useEffect(() => {
    submitData();
  }, [location.search]);

  return (
    <PageContainer title='Payment Completion'>
      <Grid container spacing={2}>
        {payment === null && <PaymentCompleteLoading />}
        {payment?.success === false &&
          <PaymentCompleteError errorCode={UMerrorCode}
                                errorTitle={UMstatus}
                                errorDescription={UMerror}
                                payment={payment} />
        }
        {payment?.success === true &&
          <PaymentCompleteSuccess payment={payment} />
        }
      </Grid>
    </PageContainer>
  );
};

export default PaymentComplete;
