import { Navigate, useLocation } from "react-router-dom";
import React, { createContext, useContext } from "react";

const AuthContext = createContext({ isAuthenticated: false });

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ isAuthenticated, children }) => {
  return (
    <AuthContext.Provider value={{ isAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
};

export const PrivateRoute = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const location = useLocation();
  return isAuthenticated ? children :
    <Navigate to='/auth/login' state={{ redirect: location.pathname }} />;
};

export const PublicRoute = ({ children }) => {
  const { isAuthenticated } = useAuth();

  return !isAuthenticated ? children : <Navigate to='/dashboard' />;
};
