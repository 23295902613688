import {
  Button,
  ButtonGroup,
  Container,
  Fab,
  Paper,
  Stack,
  Typography
} from "@mui/material";
import * as React from "react";
import { useEffect } from "react";
import { IconCheck, IconExclamationMark } from "@tabler/icons";
import { Link } from "react-router-dom";

const ApplyNewLoanStep3 = ({ result }) => {
  const [secondsLeft, setSecondsLeft] = React.useState(5);
  useEffect(() => {
    const interval = setInterval(() => {
      if (!result.approved) {
        clearInterval(interval);
        return 0;
      }
      setSecondsLeft((prevSeconds) => {
        if (prevSeconds <= 1) {
          clearInterval(interval);
          window.open(result.bankLoginUrl);
          return 0;
        }
        return prevSeconds - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [result]);

  return (
    <Container maxWidth='sm'>
      <Paper elevation={3} style={{ padding: "2rem", marginTop: "2rem" }}>
        <Stack direction='column' spacing={2} alignItems='center'>
          {(result.approved && !result.error) &&
            <>
              <Fab color='success' size='medium'>
                <IconCheck width={24} />
              </Fab>
              <Typography variant='body1'>
                Your application is created, complete the banking link below to
                complete the process.
                Once we have the banking link successfully completed, we need
                about
                an hour during normal business days, to complete your review and
                approval.
              </Typography>
              <Typography variant='body1' py={3} align='center'>
                You will be automatically redirected
                in {secondsLeft} seconds
                ... <br /> If
                the redirection doesn't occur, please click the <b>"Complete
                bank
                login"</b> button below to proceed to the bank login link.
              </Typography>
              <ButtonGroup>
                <Button component={Link} to={result.bankLoginUrl}
                        target='_blank'
                        rel='noopener'
                        variant='contained'
                        type='button'
                        color='success'>Complete bank login</Button>
                <Button component={Link} to={result.signUrl}
                        target='_blank'
                        rel='noopener'
                        variant='contained'
                        type='button'
                        color='primary'>Sign contract</Button>
              </ButtonGroup>

            </>
          }
          {(!result.approved || result.error) &&
            <>
              <Fab color='warning' size='medium'>
                <IconExclamationMark width={24} />
              </Fab>
              <Typography variant='body1'>
                Your application could not be created at this time. Please call
                our Loan Center and we will assist you with submitting the
                application.
              </Typography>
            </>
          }
          <Button component={Link} to='/dashboard' variant='contained'
                  type='button'
                  color='info'>Return Home</Button>
        </Stack>
      </Paper>
    </Container>
  );
};

export default ApplyNewLoanStep3;
