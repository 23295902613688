import {
  Box,
  Button,
  Fab,
  Grid,
  Paper,
  Stack,
  Typography
} from "@mui/material";
import { IconCheck } from "@tabler/icons";
import { Link } from "react-router-dom";
import React from "react";
import moment from "moment-timezone";
import tenant from "../../tenant";


const PaymentCompleteSuccess = ({ payment }) => {
  return (
    <>
      <Grid item xs={12} sm={8} order={{ xs: 2, sm: 1 }}>
        <Paper elevation={3} style={{ padding: "2rem", marginTop: "2rem" }}>
          <Stack display='flex'
                 spacing={2}
                 direction='column'
                 justifyContent='start'
                 alignItems='center'>
            <Fab color='success' size='medium'>
              <IconCheck width={24} />
            </Fab>
            <Typography variant='h5' align='center'>Payment
              confirmation</Typography>
          </Stack>
          <Stack display='flex'
                 spacing={2}
                 direction='column'
                 justifyContent='start'
                 alignItems='start'
                 mt={3}>
            <Typography>
              Thank you for your online payment to {tenant.name} Cash Lenders.
            </Typography>
            <Typography>
              If a balance remains after today's payment, your account dashboard
              will reflect updated balance due within 2 business days.
            </Typography>
            <Typography>
              Please retain the confirmation number for future reference of this
              transaction.
            </Typography>
            <Typography>
              If you need additional assistance or the information you submitted
              is incorrect, please contact us.
            </Typography>
          </Stack>
          <Box mt={3} display='flex' justifyContent='center'
               alignItems='center'>
            <Button variant='contained' color='primary' component={Link}
                    to='/dashboard'>
              Return to dashboard
            </Button>
          </Box>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={4} order={{ xs: 1, sm: 2 }}>
        <Paper elevation={3} style={{ padding: "2rem", marginTop: "2rem" }}>
          <Typography variant='h6' mb={2}>
            Payment summary
          </Typography>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Typography variant='body2'>
                Date of payment:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='body2'>
                {moment.utc(payment.paymentDate).tz(moment.tz.guess()).format("MMMM DD, yyyy")}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='body2'>
                Payment Time:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='body2'>
                {moment.utc(payment.paymentDate).tz(moment.tz.guess()).format("HH:mm z")}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='body2'>
                Total Paid:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='body2'>
                ${payment.amount.toFixed(2)}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='body2'>
                Payment Method:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='body2'>
                Debit card
              </Typography>
            </Grid>
          </Grid>
          <Typography variant='h6' mt={3} mb={2}>
            Payment details
          </Typography>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant='body2'>
                {tenant.name} Cash Lenders
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='body2'>
                Confirmation Number:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='body2'>
                {payment.refNum}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='body2'>
                Card Number:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='body2'>
                {payment.cardNumber}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='body2'>
                Name on a card:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='body2'>
                {payment.cardHolder}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </>
  );
};

export default PaymentCompleteSuccess;
