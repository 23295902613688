import axios from "axios";
import store from "../store";
import { logout } from "../actions/authActions";

const api = axios.create({
  headers: {
    "X-Tenant": process.env.REACT_APP_TENANT
  }
});
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("authToken");
    if (token) {
      config.headers["x-auth-session"] = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  error => {
    if (error.response && error.response.status === 401) {
      store.dispatch(logout());
      return Promise.resolve();
    }
    return Promise.reject(error);
  }
);

const apiService = {
  login: (form) => api.post("/api/v1/auth/login", form),
  logout: () => api.get("/api/v1/auth/logout"),
  getEmailBySsn: (form) => api.post("/api/v1/auth/get-email-by-ssn", form),
  getProfile: () => api.get("/api/v1/account"),
  getLoanData: (date) => api.get(`/api/v1/loan/data?paymentDate=${date}`),
  getLoanLastActivity: () => api.get(`/api/v1/loan/apply/last-activity`)
    .catch((error) => error.response && error.response.status === 404 ? { data: {} } : error),
  applyNewLoan: (form) => api.post(`/api/v1/loan/apply/new`, form),
  getCustomerData: () => api.get(`/api/v1/account/apply-form-data`),
  getPaymentFormData: (amount) => api.get(`/api/v1/payment/form-data?amount=${amount}`),
  getBillingInfo: () => api.get(`/api/v1/payment/billing-info`),
  updateBillingInfo: (form) => api.post(`/api/v1/payment/billing-info`, form),
  getPaymentInfo: () => api.get(`/api/v1/payment/payment-info`),
  removePaymentInfo: (id) => api.delete(`/api/v1/payment/payment-info/${id}`),
  updatePrimaryPayment: (id) => api.put(`/api/v1/payment/payment-info/${id}/primary`, null),
  completePayment: (form) => api.post(`/api/v1/payment/complete`, form),
  getPaymentLastActivity: () => api.get(`/api/v1/payment/last-activity`)
    .catch((error) => error.response && error.response.status === 404 ? { data: {} } : error),
  getActivities: () => api.get(`/api/v1/activities`),
  requestCallback: (form) => api.post("/api/v1/account/request-callback", form)
};

export default apiService;
