import DashboardCard from "./shared/DashboardCard";
import { Alert, Box, Divider, Fab, Stack, Typography } from "@mui/material";
import { IconCurrencyDollar } from "@tabler/icons";
import React from "react";
import { formatDate } from "../../services/date-uitils";
import DashboardCardLoading from "./shared/DashboardCardLoading";
import { formatPriceNumber } from "../../services/string-utils";

const BalanceDetails = ({ user, loan }) => {
  if (!user || !loan) {
    return <DashboardCardLoading />;
  }
  if (user.pendingReUp === true) {
    return null;
  }
  const isNotShowBalances = !user.open && user.good && !user.pendingReup;
  const dateStr = formatDate(new Date());
  const lastPaymentDateStr = formatDate(loan.lastPaymentDate);
  return (
    <DashboardCard title='Current Loan Balance Breakdown'
                   action={
                     <Fab color='secondary' size='medium'
                          sx={{ color: "#ffffff", cursor: "default" }}>
                       <IconCurrencyDollar width={24} />
                     </Fab>
                   }
    >
      <Stack spacing={5}
             direction='row'>
        <Stack direction='column'
               display='flex'
               spacing={1}
               sx={{ flexGrow: 1 }}>
          <Stack spacing={2}
                 display='flex'
                 direction='row'
                 justifyContent='space-between'
                 alignItems='center'
                 sx={{ flexGrow: 1 }}>
            <Typography variant='h6'>+ Principal outstanding:</Typography>
            <Typography
              variant='h6'>${isNotShowBalances ? "0.00" : formatPriceNumber(loan.principalOutstanding)}</Typography>
          </Stack>
          <Stack spacing={2}
                 display='flex'
                 direction='row'
                 justifyContent='space-between'
                 alignItems='center'
                 sx={{ flexGrow: 1 }}>
            <Typography variant='h6'>+ Current fees and charges:</Typography>
            <Typography
              variant='h6'>${isNotShowBalances ? "0.00" : formatPriceNumber(loan.feesAndCharges)}</Typography>
          </Stack>
          <Divider />
          <Stack spacing={2}
                 display='flex'
                 direction='row'
                 justifyContent='space-between'
                 alignItems='center'
                 sx={{ flexGrow: 1 }}>
            <Typography variant='h6'>= Remaining Payoff Amount</Typography>
            <Typography
              variant='h6'>${isNotShowBalances ? "0.00" : formatPriceNumber(loan.currentLoanBalance)}</Typography>
          </Stack>
          {loan.scheduledPaymentDate !== null && <Stack spacing={2}
                                                        display='flex'
                                                        direction='row'
                                                        justifyContent='space-between'
                                                        alignItems='center'
                                                        sx={{ flexGrow: 1 }}>
            <Typography variant='h6'> + Payment Scheduled:</Typography>
            <Typography
              variant='h6'>${isNotShowBalances ? "0.00" : formatPriceNumber(loan.scheduledPaymentAmount)}</Typography>
          </Stack>}
          <Divider />
          <Stack spacing={2}
                 display='flex'
                 direction='row'
                 justifyContent='space-between'
                 alignItems='center'
                 sx={{ flexGrow: 1 }}>
            <Typography variant='h6'>= Total
              Balance {loan.scheduledPaymentDate !== null && "plus Scheduled Payment"}</Typography>
            <Typography
              variant='h6'>${isNotShowBalances ? "0.00" : formatPriceNumber(loan.currentLoanBalance + loan.scheduledPaymentAmount)}</Typography>
          </Stack>
          <Box height={15} />
          {!isNotShowBalances && <Typography variant='h7' color='textSecondary'>
            Your total loan balances is updated daily with additional interest
            costs. This balance reflects a payoff as of today ({dateStr})
          </Typography>}
          {(loan.lastPaymentStatus === "SENT" && lastPaymentDateStr !== "") &&
            <Alert severity='warning'>
              <Typography variant='h6'>
                Your Pending payment of ${loan.lastPaymentAmount} processed
                on{" "}
                {lastPaymentDateStr} is still reflected as Pending in our system for up to 3 business days.
                Even thought the amount may have already cleared your account.
                If this payment is rejected by your bank for any reason, then this
                amount will remain as an open obligation on your account and
                will need to be paid.</Typography>
            </Alert>}
          {(user.open && !user.good) && <Typography variant='h6' pt={2}>
            Due to failed scheduled payments in the past, your loan account is
            now delinquent.
          </Typography>}
        </Stack>
      </Stack>
    </DashboardCard>
  );
};

export default BalanceDetails;
